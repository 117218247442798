<template>
  <div class="tenant-list">
      <div v-if="authIsLoading" class="absolute grid place-content-center z-50 w-full h-full bg-white bg-opacity-10 backdrop-filter backdrop-blur-sm">
        <svg class="animate-spin" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="13.25" cy="12.75" r="10.75" stroke="#F9CFE1" stroke-width="4"/>
          <path d="M24 12.75C24 6.81294 19.1871 2 13.25 2C7.31294 2 2.5 6.81294 2.5 12.75C2.5 18.6871 7.31294 23.5 13.25 23.5" stroke="#F42272" stroke-width="4" stroke-linecap="round"/>
        </svg>
      </div>
      <table v-else-if="tenants.length >= 1" class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tenant
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="tenant in tenants" :key="tenant.slug" @click="goToDashboard(tenant)" class="hover:bg-gray-100 cursor-pointer">
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900 underline">
              {{ tenant.name }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="rounded-md bg-gray-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-600 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm text-gray-800">
              <strong>You are not authorized to access this page.</strong> If you believe this is an error, <a href="mailto:help@particleone.com" class="font-medium inline-block text-pink-500 underline hover:no-underline">please contact us</a>.
            </h3>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TenantList',
  async mounted() {
    await this.authorize();
    if (this.tenants.length === 1) {
      this.goToDashboard(this.tenants[0]);
    }
  },
  computed: {
    ...mapGetters(['tenants', 'authIsLoading'])
  },
  methods: {
    async goToDashboard(tenant) {
      this.$router.push({ name: 'AppContainer', params: { tenantId: tenant.slug } });
    },
    ...mapActions(['authorize'])
  }
};
</script>

<style scoped>
</style>
